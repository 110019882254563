import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import InputTextarea, { InputTextareaProps } from '~/components/input/InputTextarea';
import { useEffect } from 'react';

export default function ZodFieldTextarea({
  name,
  ...props
}: Omit<InputTextareaProps, 'value'> & {
  name: string;
  clearOnUnmount?: boolean;
}) {
  const [prefixed, value, setValue] = useZodFormFieldSingle(name);

  useEffect(() => {
    return () => {
      if (props.clearOnUnmount) {
        setValue('');
      }
    };
  }, [props.clearOnUnmount]);

  return (
    <InputTextarea
      {...props}
      name={prefixed}
      value={value ?? ''}
      onChange={(value) => {
        setValue(value || '');

        props.onChange?.(value);
      }}
    />
  );
}
